export const GQL_ENDPOINT = window.location.hostname.includes("fiji.travel") ? "https://fiji-lms-api.devlab.zone/api/graphql" : window.location.hostname.includes("webflow.io") ? "https://fiji-lms-api.devlab.zone/api/graphql" : window.location.hostname.includes("devlab.zone") ? "https://fiji-lms-staging-api.devlab.zone/api/graphql" : "http://localhost:4063/api/graphql"

export const S3_BASE_URL = window.location.hostname.includes("fiji.travel") ? "https://fiji-lms-prod.s3.amazonaws.com/" : window.location.hostname.includes("webflow.io") ? "https://fiji-lms-prod.s3.amazonaws.com/" : window.location.hostname.includes("devlab.zone") ? "https://fiji-lms-prod.s3.amazonaws.com/" : "https://fiji-lms-prod.s3.amazonaws.com/";

export const ADMIN_PATHS = {
    signIn: "/admin/sign-in",
    adminVerification: "/admin/verification",
    forgotPassword: "/admin/forgot-password",
    resetPassword: "/admin/reset-password",
    dashboard: "/admin/overview",
    settings: "/admin/settings",
    userReports: "/admin/user/activity",
    userListing: "/admin/user/list",
    userDetails: "/admin/user/view",
    courseReports: "/admin/course/reports",
    courseListing: "/admin/course/list",
    courseDetail: "/admin/course/view",
    googleAnalytics: "/admin/analytics/ga",
    bookingList: "/admin/bookings/list"
};

export const PUBLIC_PATHS = {
    landingPage: "",
    signIn: "/user/sign-in",
    signUp: "/user/sign-up",
    userVerification: "/user/verification",
    forgotPassword: "/user/forgot-password",
    resetPassword: "/user/reset-password",
    userDisabled: "/user/disabled",
    dashboard: "/user/dashboard",
    settings: "/user/settings",
    courseList: "/user/courses",
    courseDetail: "/user/courses/(.*)",
    lessonDetail: "/lessons/(.*)",
    lessonDetailRoute: "/lessons",
    courseHighlightRoute: "/course-highlights",
    courseHighlight: "/course-highlights/(.*)",
    newsList: "/user/news",
    newsDetail: "/news/(.*)",
    newsDetailRoute: "/news",
    courseDetailRoute: "/user/courses",
    resources: "/user/resources",
    support: "/user/support",
    bookingList: "/user/bookings/list",
    bookingDetail: "/user/bookings/view"
};

const WORDS_PER_MINUTE = 200;