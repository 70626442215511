import { WFComponent } from "@xatom/core";
import { moment } from "./datePicker";
import { GetAllCoursesWithLessonsDocument } from "../../apps/client/src/graphql/graphql";
import { publicQL } from "../../apps/client/src/graphql";
import { lpBGImages } from "./commonVariables";

export const formatDate = (inputDate, noWords = false) => {
    const currentDate = new Date();
    const targetDate = new Date(inputDate);

    if (!noWords) {
        // Check if it's today
        if (
            targetDate.getDate() === currentDate.getDate() &&
            targetDate.getMonth() === currentDate.getMonth() &&
            targetDate.getFullYear() === currentDate.getFullYear()
        ) {
            return 'Today';
        }

        // Check if it's yesterday
        const yesterday = new Date(currentDate);
        yesterday.setDate(currentDate.getDate() - 1);

        if (
            targetDate.getDate() === yesterday.getDate() &&
            targetDate.getMonth() === yesterday.getMonth() &&
            targetDate.getFullYear() === yesterday.getFullYear()
        ) {
            return 'Yesterday';
        }
    }

    // Format the date in the specified manner
    return targetDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
}

export const sortList = (list, type, order?) => {
    if (order && order === 'asc') {
        if (type === 'byLastLogin') {
            return list.sort((a, b) => {
                const dateA = new Date(b.lastLogin.split('/').reverse().join('/')) as any;
                const dateB = new Date(a.lastLogin.split('/').reverse().join('/')) as any;
                return dateB - dateA;
            });
        } else if (type === 'byJoinedDate') {
            return list.sort((a, b) => {
                const dateA = new Date(b.joinDate.split('/').reverse().join('/')) as any;
                const dateB = new Date(a.joinDate.split('/').reverse().join('/')) as any;
                return dateB - dateA;
            });
        }
    } else if (order && order === 'desc') {
        if (type === 'byLastLogin') {
            return list.sort((a, b) => {
                const dateA = new Date(b.lastLogin.split('/').reverse().join('/')) as any;
                const dateB = new Date(a.lastLogin.split('/').reverse().join('/')) as any;
                return dateA - dateB;
            });
        } else if (type === 'byJoinedDate') {
            return list.sort((a, b) => {
                const dateA = new Date(b.joinDate.split('/').reverse().join('/')) as any;
                const dateB = new Date(a.joinDate.split('/').reverse().join('/')) as any;
                return dateA - dateB;
            });
        }
    } else if (order === 'enabledFirst' && type === 'byStatus') {
        return list.sort((a, b) => {
            return b.status - a.status;
        });
    } else if (order === 'disabledFirst' && type === 'byStatus') {
        return list.sort((a, b) => {
            return a.status - b.status;
        });
    } else if (order === 'completedFirst' && type === 'byProgress') {
        return list.sort((a, b) => {
            if (a["course-status"] === "COMPLETE" && b["course-status"] === "IN_PROGRESS") {
                return -1; // "a" should come before "b"
            } else if (a["course-status"] === "IN_PROGRESS" && b["course-status"] === "COMPLETE") {
                return 1; // "b" should come before "a"
            } else {
                return 0; // Leave order unchanged
            }
        });
    } else if (order === 'notCompletedFirst' && type === 'byProgress') {
        return list.sort((a, b) => {
            if (a["course-status"] === "IN_PROGRESS" && b["course-status"] === "COMPLETE") {
                return -1; // "a" should come before "b"
            } else if (a["course-status"] === "COMPLETE" && b["course-status"] === "IN_PROGRESS") {
                return 1; // "b" should come before "a"
            } else {
                return 0; // Leave order unchanged
            }
        });
    }
}

export const sortByDate = (list: any, field: string, order?: string) => {
    if (order === 'asc') {
        return list.sort((a, b) => {
            const dateA = new Date(b[field].split('/').reverse().join('/')) as any;
            const dateB = new Date(a[field].split('/').reverse().join('/')) as any;
            return dateB - dateA;
        })
    } else if (order === 'desc') {
        return list.sort((a, b) => {
            const dateA = new Date(b[field].split('/').reverse().join('/')) as any;
            const dateB = new Date(a[field].split('/').reverse().join('/')) as any;
            return dateA - dateB;
        })
    }
}

export const sortByValue = (list: any, field: string, order?: string) => {
    if (order === 'asc') {
        return list.sort((a, b) => {
            return a[field] - b[field];
        });
    } else if (order === 'desc'){
        return list.sort((a, b) => {
            return b[field] - a[field];
        });
    }
}

export const setCourseProgressRing = (courseCard: WFComponent<HTMLElement>, totalLessons: number, completedLessons: number) => {
    let circularProgress = courseCard.getChildAsComponent(".circular-progress");
    let progressStartValue = 0,
        speed = 10;
    const progressPercentage = (completedLessons * 100) / totalLessons;
    let progress = setInterval(() => {
        progressStartValue++;
        circularProgress.getElement().style.background = `conic-gradient(#00B8B4 ${progressStartValue * 3.6}deg, #ededed 0deg)`
        if (progressStartValue >= progressPercentage) {
            clearInterval(progress);
        }
    }, speed);
}

export const generateCourseAxes = (noOfCourses) => {
    let xValues = []
    for (let i = 1; i <= 6; i++) {
        if (i <= noOfCourses) {
            xValues.push(`C${i}`);
        } else {
            xValues.push("");
        }
    }
    return xValues;
}

export const defineDateRange = (rangeText: string) => {
    // console.log(rangeText)
    switch (rangeText) {
        case "Today":
            return [moment(), moment()];
            break;

        case "Yesterday":
            return [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
            break;

        case "Last 7 days":

            return [moment().subtract(6, 'days'), moment()];
            break;

        case "Last 30 days":
            return [moment().subtract(30, 'days'), moment()];
            break;

        case "This Month":
            return [moment().startOf('month'), moment()];
            break;

        case "Last 90 Days":
            return [moment().subtract(89, 'days'), moment()];
            break;

        case "Last 12 Months":
            return [moment().subtract(11, 'months').startOf('month'), moment()];
            break;

        case "Last 2 Years":
            return [moment().subtract(2, 'years').startOf('year'), moment()];
            break;

        default:
            return [5, 2];
            break;
    }
}

export const setOTPInput = () => {
    const codes: any = document.querySelectorAll('.form-input-verification_code');

    codes[0].focus();

    codes.forEach((code, idx) => {
        code.addEventListener("keyup", (e) => {
            if (e.key >= 0 && e.key <= 9) {
                codes[idx].value = e.key;
                requestAnimationFrame(() => {
                    if (codes[idx + 1]) {
                        codes[idx + 1].focus();
                        // codes[idx + 1].value = "";
                    }
                });
            } else if (e.key === "Backspace") {
                requestAnimationFrame(() => {
                    if (codes[idx - 1]) {
                        codes[idx].value = "";
                        codes[idx - 1].focus();
                    }
                })
            }
        });

        code.addEventListener("paste", (e) => {
            e.preventDefault();
            const inputValue = (e.clipboardData || window.Clipboard).getData('text');
            console.log(inputValue.length);
            if (/^\d{4}$/.test(inputValue) && inputValue.length === 4) {
                console.log("executed");
                // Auto-distribute digits to the respective input fields
                codes.forEach((digitInput, i) => {
                    digitInput.value = inputValue[i];
                });

                // Focus on the last input field
                codes[codes.length - 1].focus();
            }
        });
    });
}

export const setTriDoughnutChart = (chart: WFComponent<HTMLElement>, totalCount: number, value1: number, value2: number, value3: number) => {
    let degValue1: number, degValue2: number, degValue3: number;
    degValue1 = (354 / totalCount) * value1;
    degValue2 = (354 / totalCount) * value2;
    degValue3 = (354 / totalCount) * value3;

    if (degValue1) {

    }
    chart.getElement().style.background = `conic-gradient(
        var(--border-color--error) 0deg, 
        var(--border-color--error) ${degValue1}deg, 
        var(--background-color--card-background) ${degValue1}deg,
        var(--background-color--card-background) ${degValue1 + 2}deg,
        var(--border-color--warning) ${degValue1 + 2}deg, 
        var(--border-color--warning) ${degValue1 + degValue2 + 2}deg,
        var(--background-color--card-background) ${degValue1 + degValue2 + 2}deg, 
        var(--background-color--card-background) ${degValue1 + degValue2 + 4}deg,
        var(--border-color--success) ${degValue1 + degValue2 + 4}deg,
        var(--border-color--success) ${degValue1 + degValue2 + degValue3 + 4}deg,
        var(--background-color--card-background) ${degValue1 + degValue2 + degValue3 + 4}deg,
        var(--background-color--card-background) 360deg
    )`;
}

export const setWeglot = (isAbbriviated: boolean = false) => {
    if (!Weglot) {
        return;
    }

    const list = new WFComponent(`[xa-type="links"]`);
    list.setHTML("");
    const toggleFlag = new WFComponent(`[xa-type="toggle-flag"]`);
    const toggleText = new WFComponent(`[xa-type="toggle-text"]`);
    toggleText.setText("");
    let selectedLang = "en";
    const changeLanguage = (lang: string) => {
        Weglot.switchTo(lang);
    };

    Weglot.on("languageChanged", (newLang: string) => {
        list.setHTML("");
        selectedLang = newLang;
        setLanguages();
    })

    const setLanguages = () => {
        let availableLanguages = ["en", "it", "fr","de","zh"];
        for (let i = 0; i < availableLanguages.length; i++) {
            let lang = availableLanguages[i];
            const a = document.createElement("a");
            a.className = "sidebar_dropdown-link w-inline-block";
            a.tabIndex = 0;
            a.href = "#";
            const img = document.createElement("img");
            img.loading = "lazy";
            img.className = "sidebar_language_flag-img";
            const div = document.createElement("div");
            div.className = "text-size-small";
            if (lang === "en") {
                const imgURL = "https://cdn.weglot.com/flags/rectangle_mat/gb.svg";
                img.src = imgURL;
                img.srcset = imgURL;
                div.innerText = "English"
                a.onclick = () => {
                    changeLanguage(lang);
                };
            } else if (lang === "ja") {
                const imgURL = "https://cdn.weglot.com/flags/rectangle_mat/jp.svg"
                img.src = imgURL;
                img.srcset = imgURL;
                div.innerText = "日本語"
                a.onclick = () => {
                    changeLanguage(lang);
                };
            } else if (lang === "es") {
                const imgURL = "https://cdn.weglot.com/flags/rectangle_mat/es.svg";
                img.src = imgURL;
                img.srcset = imgURL;
                div.innerText = "Español"
                a.onclick = () => {
                    changeLanguage(lang);
                };
            } else if (lang === "pt") {
                const imgURL = "https://cdn.weglot.com/flags/rectangle_mat/pt.svg";
                img.src = imgURL;
                img.srcset = imgURL;
                div.innerText = "Português"
                a.onclick = () => {
                    changeLanguage(lang);
                };
            } else if (lang === "it") {
                const imgURL = "https://cdn.weglot.com/flags/rectangle_mat/it.svg";
                img.src = imgURL;
                img.srcset = imgURL;
                div.innerText = "Italiano"
                a.onclick = () => {
                    changeLanguage(lang);
                };

            } else if (lang === "de") {
                const imgURL = "https://cdn.weglot.com/flags/rectangle_mat/de.svg";
                img.src = imgURL;
                img.srcset = imgURL;
                div.innerText = "German"
                a.onclick = () => {
                    changeLanguage(lang);
                };
            } else if (lang === "fr") {
                const imgURL = "https://cdn.weglot.com/flags/rectangle_mat/fr.svg";
                img.src = imgURL;
                img.srcset = imgURL;
                div.innerText = "Français"
                a.onclick = () => {
                    changeLanguage(lang);
                };
            } else if (lang === "zh") {
                const imgURL = "https://cdn.weglot.com/flags/rectangle_mat/cn.svg";
                img.src = imgURL;
                img.srcset = imgURL;
                div.innerText = "中文 (简体)"
                a.onclick = () => {
                    changeLanguage(lang);
                };
            } else if (lang === "ru") {
                const imgURL = "https://cdn.weglot.com/flags/rectangle_mat/ru.svg";
                img.src = imgURL;
                img.srcset = imgURL;
                div.innerText = "Русский"
                a.onclick = () => {
                    changeLanguage(lang);
                };
            } else if (lang === "ro") {
                const imgURL = "https://cdn.weglot.com/flags/rectangle_mat/ro.svg";
                img.src = imgURL;
                img.srcset = imgURL;
                div.innerText = "Română"
                a.onclick = () => {
                    changeLanguage(lang);
                };
            }
            a.appendChild(img);
            a.appendChild(div);
            list.appendChild(a);
        }
        setSelectedLanguage();
    }


    const setSelectedLanguage = () => {
        let imgURL = "", languageText = "";
        if (selectedLang === "en") {
            imgURL = "https://cdn.weglot.com/flags/rectangle_mat/gb.svg";
            if (isAbbriviated) {
                languageText = "EN"
            } else {
                languageText = "English"
            }
        } else if (selectedLang === "ja") {
            imgURL = "https://cdn.weglot.com/flags/rectangle_mat/jp.svg"
            if (isAbbriviated) {
                languageText = "JA"
            } else {
                languageText = "日本語"
            }
        } else if (selectedLang === "es") {
            imgURL = "https://cdn.weglot.com/flags/rectangle_mat/es.svg";
            if (isAbbriviated) {
                languageText = "ES"
            } else {
                languageText = "Español"
            }
        } else if (selectedLang === "pt") {
            imgURL = "https://cdn.weglot.com/flags/rectangle_mat/pt.svg";
            if (isAbbriviated) {
                languageText = "PT"
            } else {
                languageText = "Português"
            }
        } else if (selectedLang === "it") {
            imgURL = "https://cdn.weglot.com/flags/rectangle_mat/it.svg";
            if (isAbbriviated) {
                languageText = "IT"
            } else {
                languageText = "Italiano"
            }
        } else if (selectedLang === "de") {
            imgURL = "https://cdn.weglot.com/flags/rectangle_mat/de.svg";
            if (isAbbriviated) {
                languageText = "DE"
            } else {
                languageText = "Deutsch"
            }
        } else if (selectedLang === "fr") {
            imgURL = "https://cdn.weglot.com/flags/rectangle_mat/fr.svg";
            if (isAbbriviated) {
                languageText = "FR"
            } else {
                languageText = "Français"
            }
        } else if (selectedLang === "zh") {
            imgURL = "https://cdn.weglot.com/flags/rectangle_mat/cn.svg";
            if (isAbbriviated) {
                languageText = "ZH"
            } else {
                languageText = "中文 (简体)"
            }
        } else if (selectedLang === "ru") {
            imgURL = "https://cdn.weglot.com/flags/rectangle_mat/ru.svg";
            if (isAbbriviated) {
                languageText = "RU"
            } else {
                languageText = "Русский"
            }
        } else if (selectedLang === "ro") {
            imgURL = "https://cdn.weglot.com/flags/rectangle_mat/ro.svg";
            if (isAbbriviated) {
                languageText = "RO"
            } else {
                languageText = "Română"
            }
        }
        toggleText.setText(languageText);
        toggleFlag.setAttribute("src", imgURL);
        toggleFlag.setAttribute("srcSet", imgURL);
    }

    setLanguages();
}

export const getGreeting = () => {
    // Get the current time
    const now = new Date();
    const hours = now.getHours();

    // Determine the appropriate greeting
    let greeting;
    if (hours < 12) {
        greeting = "Good morning";
    } else if (hours < 18) {
        greeting = "Good afternoon";
    } else {
        greeting = "Good evening";
    }

    return greeting;
}

export const toCamelCase = (str) => {
    return str
        .toLowerCase()
        .split(/[\s_-]+/)
        .map((word, index) => index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1))
        .join('');
}

export const capitalizeFirstLetter = (str) => {
    return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
}

export const handleResize = () => {
    const screenWidth = window.innerWidth;
    console.log(`Current screen width: ${screenWidth}px`);

    // Add your custom logic here
    if (screenWidth < 768) {
        console.log('Screen width is less than 768px (mobile view)');
        // Do something for mobile view
    } else if (screenWidth < 1024) {
        console.log('Screen width is less than 1024px (tablet view)');
        // Do something for tablet view
    } else {
        console.log('Screen width is 1024px or more (desktop view)');
        // Do something for desktop view
    }
}

export const setLearningPath = (courseProgressData) => {
    const learningPath = new WFComponent(`[xa-type="learning-path-list"]`);
    const signBoards = learningPath.getChildAsComponents(`[xa-type="badge"]`);
    const completedCourses = courseProgressData.getAllCoursesProgress.filter(cp => cp.status === "COMPLETE");
    const completedCourseNames = completedCourses.map(progress => progress.course.name);
    const getAllCoursesReq = publicQL.query(GetAllCoursesWithLessonsDocument);

    // To show appropriate number of signboards
    let availableSignBoards = [];

    // for lottie
    let skiStage =
        completedCourses.length <= 13
            ? completedCourses.length
            : completedCourses.length % 13;


    getAllCoursesReq.onData((data) => {
        const courses = data.getAllCoursesWithLessons;
        const sortedCourses = [...courses].sort((a, b) => {
            const isACompleted = completedCourseNames.includes(a.name);
            const isBCompleted = completedCourseNames.includes(b.name);

            if (isACompleted && !isBCompleted) return -1;
            if (!isACompleted && isBCompleted) return 1;
            return 0;
        });

        // Hide signboards more than course count
        if (signBoards.length > sortedCourses.length) {
            signBoards.forEach((sb, index) => {
                if (index > (sortedCourses.length - 1)) {
                    sb.addCssClass("hide");
                } else {
                    availableSignBoards.push(sb);
                }
            })
        } else {
            availableSignBoards = signBoards;
        }

        // Sort courses based on completed first.
        console.log(completedCourses)
        // Update course names on signboard tooltip
        if (sortedCourses && sortedCourses.length) {
            for (let i = 0; i < sortedCourses.length; i++) {
                if (signBoards[i]) {
                    const courseName = sortedCourses[i].name;
                    signBoards[i].updateTextViaAttrVar({
                        "course-name": courseName
                    });
                    signBoards[i].setAttribute("course-name", `${courseName}`);
                }
            }
        }

        // Update signboard image & ski lottie
        if (completedCourses.length && availableSignBoards.length) {
            completedCourses.forEach(course => {
                const sb = signBoards.find(sb => sb.getAttribute("course-name") === course.course.name);
                const signBoardImage = sb.getChildAsComponent(`[xa-type="sign-board-img"]`);
                const sbURL = course.course.courseSignboard;
                signBoardImage.setAttribute("src", sbURL);
                signBoardImage.setAttribute("srcset", sbURL);
            });

            setTimeout(() => {
                learningPath.getChildAsComponent(`[xa-type="lp-trigger-stage${skiStage}"]`).getElement().click();
            }, 500);
        }
    })

    getAllCoursesReq.fetch();
}

export const setLearningPathTab = (courseProgressData) => {
    const learningPath = new WFComponent(`[xa-type="learning-path-list-tab"]`);
    const signBoards = learningPath.getChildAsComponents(`[xa-type="badge"]`);
    const completedCourses = courseProgressData.getAllCoursesProgress.filter(cp => cp.status === "COMPLETE");
    const completedCourseNames = completedCourses.map(progress => progress.course.name);
    const getAllCoursesReq = publicQL.query(GetAllCoursesWithLessonsDocument);

    // To show appropriate number of signboards
    let availableSignBoards = [];

    // for lottie
    // let skiStage =
    //     completedCourses.length <= 13
    //         ? completedCourses.length
    //         : completedCourses.length % 13;


    getAllCoursesReq.onData((data) => {
        const courses = data.getAllCoursesWithLessons;
        const sortedCourses = [...courses].sort((a, b) => {
            const isACompleted = completedCourseNames.includes(a.name);
            const isBCompleted = completedCourseNames.includes(b.name);

            if (isACompleted && !isBCompleted) return -1;
            if (!isACompleted && isBCompleted) return 1;
            return 0;
        });

        // Hide signboards more than course count
        // if (signBoards.length > sortedCourses.length) {
        //     signBoards.forEach((sb, index) => {
        //         if (index > (sortedCourses.length - 1)) {
        //             sb.addCssClass("hide");
        //         } else {
        //             availableSignBoards.push(sb);
        //         }
        //     })
        // } else {
        //     availableSignBoards = signBoards;
        // }

        // // Sort courses based on completed first.
        // console.log(completedCourses)
        // // Update course names on signboard tooltip
        // if (sortedCourses && sortedCourses.length) {
        //     for (let i = 0; i < sortedCourses.length; i++) {
        //         if (signBoards[i]) {
        //             const courseName = sortedCourses[i].name;
        //             signBoards[i].updateTextViaAttrVar({
        //                 "course-name": courseName
        //             });
        //             signBoards[i].setAttribute("course-name", `${courseName}`);
        //         }
        //     }
        // }

        // Update signboard image & ski lottie
        if (completedCourses.length && availableSignBoards.length) {
            completedCourses.forEach(course => {
                const sb = signBoards.find(sb => sb.getAttribute("course-name") === course.course.name);
                const signBoardImage = sb.getChildAsComponent(`[xa-type="sign-board-img"]`);
                const sbURL = course.course.courseSignboard;
                signBoardImage.setAttribute("src", sbURL);
                signBoardImage.setAttribute("srcset", sbURL);
            });

            // setTimeout(() => {
            //     learningPath.getChildAsComponent(`[xa-type="lp-trigger-stage${skiStage}"]`).getElement().click();
            // }, 500);
        }
    })

    getAllCoursesReq.fetch();
}

export const paginate = (list, page = 1, perPage) => {
    const totalItems = list.length;
    const totalPages = Math.ceil(totalItems / perPage);
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;

    return {
        currentPage: page, 
        perPage: perPage,
        totalItems: totalItems,
        totalPages: totalPages, 
        list: list.slice(startIndex, endIndex)
    }
}

export const updatePaginationNav = (currentPage, totalPages, nextButton, prevButton) => {
    // Handle the case where there is only one page
    if (totalPages === 1) {
        prevButton.addCssClass("hide"); // Hide 'Previous' button
        nextButton.addCssClass("hide"); // Hide 'Next' button
    }
    // Handle the first page
    else if (currentPage === 1) {
        prevButton.addCssClass("hide"); // Hide 'Previous' button
        nextButton.removeCssClass("hide"); // Show 'Next' button
    }
    // Handle intermediate pages
    else if (currentPage > 1 && currentPage < totalPages) {
        prevButton.removeCssClass("hide"); // Show 'Previous' button
        nextButton.removeCssClass("hide"); // Show 'Next' button
    }
    // Handle the last page
    else if (currentPage === totalPages) {
        prevButton.removeCssClass("hide"); // Show 'Previous' button
        nextButton.addCssClass("hide"); // Hide 'Next' button
    }
}

export const getInitials = (name) => {
    const words = name.split(' ');
    const initials = words.map(word => word.charAt(0).toUpperCase()).join('');
    return initials;
}

export const generatePaginationArray = (pageNo, totalPages) => {
    let pageNoArr: any = [];
    if (pageNo <= 2) {
        const lastPage = (pageNo + 4 < totalPages) ? pageNo + 4 : totalPages;
        for (let i = 1; i <= lastPage; i++) {
            pageNoArr.push(i);
        }
        if (pageNo === 1 && pageNo !== totalPages && totalPages > 0) {
            pageNoArr.push(6)
        }
        if (pageNo !== totalPages && totalPages > 0) {
            pageNoArr.push(totalPages);
        }
        pageNoArr = pageNoArr.map((p) => {
            return { pageNo: p }
        });
    } else if (pageNo >= totalPages - 2) {
        pageNoArr.push(1);
        for (let i = totalPages - 5; i <= totalPages; i++) {
            pageNoArr.push(i);
        }
        pageNoArr = pageNoArr.map((p) => {
            return { pageNo: p }
        });
    } else {
        for (let i = pageNo - 2; i <= pageNo + 2; i++) {
            pageNoArr.push(i);
        }
        if (!(pageNoArr.find((pne) => parseInt(pne) === 1))) {
            pageNoArr.unshift(1);
        } else {
            pageNoArr.push(pageNoArr[pageNoArr.length - 1] + 1);
        }
        if (!(pageNoArr.find((pne) => parseInt(pne) === totalPages))) {
            pageNoArr.push(totalPages);
        } else {
            pageNoArr[0] = pageNoArr[1] - 1;
            pageNoArr.unshift(1);
        }
        pageNoArr = pageNoArr.map((p) => {
            return { pageNo: p }
        });
    }
    return pageNoArr;
}