import { WFComponent, WFFormComponent, navigate } from "@xatom/core";
import { publicQL } from "../../graphql";
import { GetAllCoursesProgressDocument, GetNumberOfCoursesDocument, PublicUploadAvatarDocument, UserMeDocument } from "../../graphql/graphql";
import { PUBLIC_PATHS, S3_BASE_URL } from "../../config";
import { logoutPublicAuth } from "../../auth/public";
import { setWeglot } from "client-utils/utility-functions";
const defaultUsrAvatar = "https://assets-global.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg";
let loaderProgress = 0;

export const userSidebar = () => {
    try {
        setWeglot();
        const userDataReq = publicQL.query(UserMeDocument);
        const avatarChangeReq = publicQL.mutation(PublicUploadAvatarDocument);
        const logoutBtn = new WFComponent(`[xa-type="sidebar-signout-btn"]`);
        logoutBtn.on("click", () => {
            logoutPublicAuth();
        });

        const sidebar = new WFComponent(`[xa-type="sidebar"]`);
        const avatar = sidebar.getChildAsComponent(`[xa-type="avatar"]`);
        const avatarImage = avatar.getChildAsComponent(`[xa-type="image"]`);
        const avatarInitials = avatar.getChildAsComponent(`[xa-type="initials"]`);
        const avatarBorder = avatar.getChildAsComponent(`[xa-type="border"]`);
        const getAllCoursesProgressReq = publicQL.query(GetAllCoursesProgressDocument);
        const getNumberOfCoursesReq = publicQL.query(GetNumberOfCoursesDocument);
        const bookingsLink = sidebar.getChildAsComponent(`[xa-type="sidebar-bookings-link"]`);
        const bookingsLinkLock = bookingsLink.getChildAsComponent(".sidebar_link-lock");

        // Courses section
        getAllCoursesProgressReq.onData((data) => {
            getNumberOfCoursesReq.onData((res) => {
                const totalCoursesCount = res.getNumberOfCourses;
                const completedLessonsCount = data.getAllCoursesProgress.filter(cp => cp.status === "COMPLETE").length;
            })
            getNumberOfCoursesReq.fetch();
        });

        getAllCoursesProgressReq.fetch();

        userDataReq.onData((data) => {
            if (!data.userMe.enabled) {
                logoutPublicAuth("disabled");
            } else {

                sidebar.updateTextViaAttrVar({
                    name: data.userMe.firstName,
                    tier: `${data.userMe.tier.name} Specialist`
                })
                const userTier = data.userMe.tier.name
                switch (userTier) {
                    case 'Newbie':
                        break;

                    case 'Bronze':
                        avatarBorder.addCssClass("is-bronze");
                        bookingsLinkLock.addCssClass("hide");
                        bookingsLink.removeCssClass("is-locked");
                        bookingsLink.on("click", (e) => {
                            e.preventDefault();
                            navigate({
                                to: `${PUBLIC_PATHS.bookingList}`,
                                type: "replace"
                            });
                        });
                        break;

                    case 'Silver':
                        avatarBorder.addCssClass("is-silver");
                        break;

                    case 'Gold':
                        avatarBorder.addCssClass("is-gold");
                        break;

                    case 'Platinum':
                        avatarBorder.addCssClass("is-platinum");
                        break;

                    default:
                        break;
                }
                if (data.userMe.avatar !== '') {
                    avatarImage.setAttribute("src", `${S3_BASE_URL}${data.userMe.avatar}`);
                    avatarImage.setAttribute("srcset", `${S3_BASE_URL}${data.userMe.avatar}`);
                    avatarImage.removeCssClass("hide");
                } else {
                    avatar.updateTextViaAttrVar({
                        initials: getInitials(data.userMe.firstName)
                    })
                    avatarInitials.removeCssClass("hide");
                }
            }
        });

        userDataReq.fetch();

        const userAvatarInput = new WFComponent(`#sidebar-dp-upload`);
        const userAvatarForm = new WFFormComponent(`[xa-type="avatar-upload-form"]`);
        // //Update user Avatar
        userAvatarInput.on("change", () => {
            if (userAvatarForm.getFormData() && userAvatarForm.getFormData().img) {
                const image: any = userAvatarForm.getFormData().img;
                avatarChangeReq.fetch({
                    imageFile: image
                });
            }
        });
        userAvatarForm.onFormSubmit((data) => {
            //do nothing
        });

        avatarChangeReq.onError((err) => {
        })

        avatarChangeReq.onData((data) => {
            userAvatarForm.resetForm();
        })

        window.addEventListener('resize', () => {
            location.reload();
        });
    } catch (err) {
        console.log("We think this page doesn't have a sidebar. Check this error - ", err.message);
    }


    function getInitials(name) {
        const words = name.split(' ');
        const initials = words.map(word => word.charAt(0).toUpperCase()).join('');
        return initials;
    }


    function getRandomValueFromArray(array) {
        const randomIndex = Math.floor(Math.random() * array.length);
        return array[randomIndex];
    }

    function loaderAnimation(loader) {
        loader.getElement().style.background = `conic-gradient(var(--background-color--cta) ${loaderProgress * 3.6}deg, var(--background-color--white) 0deg)`;
        loaderProgress = (loaderProgress + 1) % 100;
    }

    const setCourseProgressRing = (courseCard: WFComponent<HTMLElement>, totalLessons: number, completedLessons: number) => {
        let progressStartValue = 0,
            speed = 10;
        const progressPercentage = (completedLessons * 100) / totalLessons;
        let progress = setInterval(() => {
            progressStartValue++;
            courseCard.getElement().style.background = `conic-gradient(var(--background-color--cta) ${progressStartValue * 3.6}deg, var(--background-color--dark) 0deg)`
            if (progressStartValue >= progressPercentage) {
                clearInterval(progress);
            }
        }, speed);
    }
}